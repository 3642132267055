import React from 'react'
import { graphql } from 'gatsby'
import { pathOr } from 'ramda'

import { Artist as ArtistTemplate } from '../components/templates'

const Artist: React.FC<Props> = ({ data, location }) => (
  <ArtistTemplate
    title={data.contentfulArtist.name}
    bio={data.contentfulArtist?.bio?.bio}
    gatsbyImageData={pathOr('', ['contentfulArtist', 'image', 'gatsbyImageData'], data)}
    contactInfo={data.contentfulArtist?.contactInfo?.contactInfo}
    location={location}
  />
)

export default Artist

export const query = graphql`
  query ArtistQuery($slug: String!) {
    contentfulArtist(slug: { eq: $slug }) {
      name
      bio {
        bio
      }
      contactInfo {
        contactInfo
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, height: 840)
      }
    }
  }
`

interface Props {
  data: {
    contentfulArtist: {
      name: string
      bio?: {
        bio?: string
      }
      contactInfo?: {
        contactInfo?: string
      }
      image: {
        gatsbyImageData: any
      }
    }
  }
  location: any
}
